import React from "react";
import "../../utils/GradientBorder.css";
import GoogleLoginButton from "../login/GoogleLoginButton.jsx";
// import { useNavigate } from "react-router-dom";

const LoginPlatformChoiceModal = ({ onClose }) => {
  // const navigate = useNavigate();

  const handleLogin = () => {
    onClose();
    // navigate("/welcome");
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div
        className="gradient-border-2 inline-flex justify-center items-center rounded-lg"
        style={{ width: "594px", height: "580px" }}
      >
        <div
          style={{
            width: "586px",
            height: "572px",
            backgroundColor: "#444452",
          }}
          className="relative rounded-lg inner-content"
        >
          <button
            onClick={onClose}
            className="absolute top-6 right-6 text-lg font-bold"
          >
            <img
              className="w-12 h-12 fill-current"
              src="/icons/ic_close.svg"
              alt="logo"
            />
          </button>
          <div className="flex flex-col items-center justify-center h-full">
            <div className="bg-black-300 w-44 h-44 rounded-full mb-6">
              <img
                className="w-44 h-44 fill-current"
                src="/icons/ic_profile.svg"
                alt="logo"
              />
            </div>
            <div className="text-3xl font-extrabold  mb-24">
              Welcome to Todil
            </div>
            <GoogleLoginButton onLogin={handleLogin} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPlatformChoiceModal;
