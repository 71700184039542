import { useState } from "react";
import LoginPlatformChoiceModal from "./modals/LoginPlatformChoiceModal";
import LogoutModal from "./modals/LogoutModal";

const links = [
  { id: 1, title: "Today", url: "/", src: "/icons/ic_today.svg" },
  {
    id: 2,
    title: "TIL Archive",
    url: "/archive",
    src: "/icons/ic_archive.svg",
  },
];

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false); // New state for the logout modal

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = () => {
    // Perform the logout action, such as clearing the token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    setShowLogoutModal(false);
    window.location.reload();
  };

  const token = localStorage.getItem("token");
  const username = localStorage.getItem("username");

  const tempLogin = () => {
    localStorage.setItem(
      "token",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjMiLCJlbWFpbCI6InBhcms0OWpAbXRob2x5b2tlLmVkdSIsImlhdCI6MTY5MDczNjc0MSwiZXhwIjoxNjkxMzQxNTQxLCJpc3MiOiJodHRwczovL3RvZGlsLm5ldCJ9.qz503pvSJk9vFcj1sd8rgimCV7tb97NdNxsBw3pHR_4"
    );
    localStorage.setItem("username", "joni-temp");
    window.location.reload();
  };

  return (
    <nav className="w-[280px] px-4 py-12 bg-gray-500 text-white flex flex-col justify-between">
      <div>
        {token ? (
          // Show LogoutModal if token exists
          <>
            {showLogoutModal && (
              <LogoutModal
                onConfirm={handleLogoutConfirm}
                onCancel={() => setShowLogoutModal(false)}
              />
            )}
            <div className="flex items-center space-x-3 mb-6">
              <div className="bg-black-300 w-10 h-10 rounded-full">
                <img
                  className="h-10 w-10 fill-current"
                  src="/icons/ic_profile.svg"
                  height={40}
                  width={40}
                  alt="logo"
                />
              </div>
              <div className="flex flex-col space-y-1 justify-center">
                <h3 className="text-xl font-semibold">{username}</h3>
                <button
                  className="flex items-center space-x-1"
                  onClick={handleLogout}
                >
                  <p className="text-xs">Logout</p>
                  <img
                    className="h-4 w-4 fill-current"
                    src="/icons/ic_logout.svg"
                    height={32}
                    width={32}
                    alt="logout"
                  />
                </button>
              </div>
            </div>
          </>
        ) : (
          // Show LoginPlatformChoiceModal if token does not exist
          <div className="flex items-center space-x-3 mb-6">
            <div className="bg-black-300 w-10 h-10 rounded-full">
              <img
                className="h-10 w-10 fill-current"
                src="/icons/ic_profile.svg"
                height={40}
                width={40}
                alt="logo"
              />
            </div>
            <div className="flex flex-col space-y-1 justify-center">
              <h3 className="text-xl font-semibold">Welcome</h3>
              <button
                className="flex items-center space-x-1"
                onClick={() => setShowModal(true)}
              >
                <p className="text-xs">Sign up/Sign in</p>
                <img
                  className="h-4 w-4 fill-current"
                  src="/icons/ic_logout.svg"
                  height={32}
                  width={32}
                  alt="logout"
                />
              </button>
            </div>
            {/* <button onClick={tempLogin}>Temp login</button> */}
          </div>
        )}

        <div className="flex flex-col space-y-2 mt-12">
          {links.map((link) => (
            <a
              className="flex items-center space-x-2 hover:bg-gray-300 rounded-2xl p-3"
              key={link.id}
              href={link.url}
            >
              <img
                className="h-6 w-6 fill-current"
                src={link.src}
                height={32}
                width={32}
                alt={link.title}
              />
              <span>{link.title}</span>
            </a>
          ))}
        </div>
      </div>
      <a
        className="flex items-center space-x-2 bg-gray-400 px-4 py-5 rounded-2xl"
        target="_blank"
        href="https://forms.gle/JYdNuTPCHHS3osRH9"
        rel="noreferrer"
      >
        <img
          className="h-8 w-8 fill-current"
          src="/icons/ic_feedback.svg"
          height={32}
          width={32}
          alt="feedback"
        />
        <p className="text-sm">Do you have feedback?</p>
      </a>
      {showModal && <LoginPlatformChoiceModal onClose={closeModal} />}
    </nav>
  );
};

export default Navbar;
