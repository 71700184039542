import { getOrdinalSuffix } from "../utils/date";
import ReactMarkdown from "react-markdown";

const TILCard = ({ til, onClick, selected }) => (
  <div
    className={`border border-gray-300 rounded-2xl m-4 cursor-pointer ${
      selected ? "bg-gray-300" : "bg-black-100"
    }`}
    onClick={onClick}
    style={{ height: "384px", overflow: "hidden", paddingBottom: "16px" }}
  >
    <p
      className={`flex justify-end text-base mb-2 px-5 py-3  ${
        selected ? "bg-gray-200 text-black-100" : "bg-gray-400 text-blue-200"
      }`}
    >
      {`${new Date(til.createdAt).toLocaleString("default", {
        weekday: "short",
      })}. 
          ${getOrdinalSuffix(new Date(til.createdAt).getDate())} 
          ${new Date(til.createdAt).toLocaleString("default", {
            month: "short",
          })}. 
          ${new Date(til.createdAt).getFullYear()}`}
    </p>
    <div
      className="p-4 markdown-content"
      style={{ maxHeight: "calc(100% - 60px)" }}
    >
      <ReactMarkdown className="text-base">{til.content}</ReactMarkdown>
    </div>
  </div>
);

export default TILCard;
