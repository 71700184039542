import { useState, useEffect, useRef } from "react";
import axios from "axios";

import MDViewer from "../components/MDViewer";
import { Editor } from "@toast-ui/react-editor";
import TILCard from "../components/TILCard";

export default function Archive() {
  const jwt = localStorage.getItem("token"); // JWT stored in localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(false); // replace with your logic to determine if the user is authenticated

  //   const [tils, setTils] = useState([]);
  const [tils, setTils] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTIL, setSelectedTIL] = useState(null);

  // Check if the user is authenticated before making the API request
  useEffect(() => {
    if (jwt) {
      const validateToken = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/validateToken`,
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          );

          if (response.data.success) {
            setIsAuthenticated(true);
          }
        } catch (err) {
          console.error(err);
        }
      };

      validateToken(); // Call the validateToken function
    }
  }, [jwt]);

  const fetchTils = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/blocks`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      if (response.data.success) {
        setTils(response.data.data);
        // console.log(response.data);
        // if selectedTIL is null, set selectedTIL to the first TIL in the array
        if (!selectedTIL) {
          setSelectedTIL(response.data.data[0]);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchTils();
  }, [jwt]);

  const filteredTils = tils.filter((til) =>
    til.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [editing, setEditing] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);

  const handleSave = async () => {
    const editorInstance = editorRef.current.getInstance();
    const tilContent = editorInstance.getMarkdown();

    await handleEditTil(selectedTIL.id, tilContent);

    setSelectedTIL({ ...selectedTIL, content: tilContent });
    setEditing(false);
  };

  const handleEditTil = async (tilId, updatedContent) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/blocks`,
        { id: tilId, content: updatedContent },
        { headers: { Authorization: `Bearer ${jwt}` } }
      );
      fetchTils();
    } catch (err) {
      console.error(err);
    }
  };

  return isAuthenticated ? (
    <div className="flex-grow h-screen flex bg-black-300 text-white">
      <div className=" w-[360px] h-96">
        <div className="border-2 border-gray-200 rounded-xl mx-4 mt-7 mb-9 px-5 py-3 flex items-center space-x-1">
          <input
            className="bg-transparent focus:border-none outline-none w-full"
            type="search"
            placeholder="Search TIL..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <div
          className="overflow-y-auto"
          style={{ maxHeight: "calc(100vh - 120px)" }}
        >
          {filteredTils.map((til) => (
            <TILCard
              key={til.id}
              til={til}
              onClick={() => setSelectedTIL(til)}
              selected={selectedTIL && selectedTIL.id === til.id}
            />
          ))}
        </div>
      </div>
      <div className="px-12 pt-16">
        {editing ? (
          <>
            <div className="my-4 pt-12">
              <Editor
                ref={editorRef}
                initialValue={editorContent}
                previewStyle="tab"
                height="480px"
                initialEditType="markdown"
                theme="dark"
                placeholder="What did you learn today?"
                hideModeSwitch={true}
                useCommandShortcut={true}
              />
            </div>
            <div className="flex justify-end">
              <button
                className="mt-6 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-2xl"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </>
        ) : (
          <>
            {selectedTIL && (
              <div className="flex justify-end pb-6">
                <button
                  className="mt-6 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-2xl"
                  onClick={() => {
                    setEditorContent(selectedTIL.content);
                    setEditing(true);
                  }}
                >
                  Edit
                </button>
              </div>
            )}
            <MDViewer
              content={
                selectedTIL
                  ? selectedTIL.content
                  : "## Select a TIL to view details 👾"
              }
            />
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="flex-grow h-screen flex bg-black-300 text-white">
      <div className=" w-[360px] h-96">
        <div className="border-2 border-gray-200 rounded-xl mx-4 mt-7 mb-9 px-5 py-3 flex items-center space-x-1">
          <input
            className="bg-transparent focus:border-none outline-none w-full"
            type="search"
            placeholder="Search TIL..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>
      <div className="px-12 pt-16">Let’s write some TIL!</div>
    </div>
  );
}
