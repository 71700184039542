import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Archive from "./pages/Archive";
import WelcomeModal from "./components/modals/WelcomeModal";

function App() {
  return (
    <BrowserRouter>
      <div className="flex w-screen h-screen bg-gray-100">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/welcome" element={<WelcomeModal />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
