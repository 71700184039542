import React from "react";
import "../../utils/GradientBorder.css";

const LoginPlatformChoiceModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div
        className="gradient-border-2 inline-flex justify-center items-center rounded-lg"
        style={{ width: "594px", height: "400px" }}
      >
        <div
          style={{
            width: "586px",
            height: "392px",
            backgroundColor: "#444452",
          }}
          className="relative rounded-lg inner-content"
        >
          <button
            onClick={onClose}
            className="absolute top-6 right-6 text-lg font-bold"
          >
            <img
              className="w-12 h-12 fill-current"
              src="/icons/ic_close.svg"
              alt="logo"
            />
          </button>
          <div className="flex flex-col items-center justify-center h-full pt-10">
            <img
              className="w-20 h-20 fill-current"
              src="/icons/ic_ufo.svg"
              alt="logo"
            />

            <div className="text-2xl font-bold pt-3">Welcome!</div>

            <div className="flex space-x-5 pt-14">
              <button className="px-8 py-5 text-blue-200 border border-blue-200 rounded-2xl">
                Logout
              </button>
              <button className="px-8 py-5 text-white bg-blue-500 rounded-2xl">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPlatformChoiceModal;
