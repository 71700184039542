import React, { useState } from "react";
import axios from "axios";
import "../../utils/GradientBorder.css";

const SignupNicknameModal = ({ onClose, googleToken, onLogin }) => {
  const [username, setUsername] = useState("");
  const [marketingPolicy, setMarketingPolicy] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/signup`,
        {
          username,
          platform: "google",
          tokenId: googleToken,
          marketingPolicy,
        }
      );

      if (response.data.success) {
        console.log("signup success");
        console.log("response.data.data.token:", response.data.data.token);
        console.log(
          "response.data.data.username:",
          response.data.data.username
        );
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("username", response.data.data.username);
        onClose();
        onLogin(); // this will close the modal
      }
    } catch (err) {
      setErrorMessage(err.response.data.message);
      console.log("signup failed");
      console.error(err);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div
        className="gradient-border-2 inline-flex justify-center items-center rounded-lg"
        style={{ width: "594px", height: "560px" }}
      >
        <div
          style={{
            width: "586px",
            height: "552px",
            backgroundColor: "#444452",
          }}
          className="relative rounded-lg inner-content"
        >
          <div className="flex flex-col items-center justify-center h-full">
            <div className="text-white text-3xl font-extrabold mb-10 text-center">
              What's your preferred nickname?
            </div>

            <div className="flex flex-col items-center justify-center w-[400px] pt-8">
              <div className="w-full">
                <input
                  className="rounded-xl w-full h-16 py-2 px-6 bg-gray-300 text-gray-100 focus:outline-none"
                  id="nickname"
                  type="text"
                  placeholder="Enter your nickname"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              </div>
              <div className="mt-8 flex items-center space-x-4">
                <input
                  type="checkbox"
                  id="consent-checkbox"
                  className="form-checkbox h-6 w-6 text-blue-200"
                  checked={marketingPolicy}
                  onChange={() => setMarketingPolicy(!marketingPolicy)}
                />
                <label
                  for="consent-checkbox"
                  className="ml-2 block text-sm text-blue-200"
                >
                  By checking this box, you consent to receive marketing emails
                  and acknowledge that you have read and agree to our{" "}
                  <a
                    target="_blank"
                    href="https://jonipark.notion.site/Todil-Privacy-Policy-40248f29799349649f5941d7a1b3df89?pvs=4"
                    className=" underline text-blue-300 hover:text-blue-500"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>

              <div className="mt-8 w-full">
                <button
                  onClick={handleSubmit}
                  className="rounded-xl w-full h-16 py-2 px-6 bg-disabled text-disabledfont focus:outline-none"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupNicknameModal;
