import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getOrdinalSuffix } from "../utils/date";
import "../utils/GradientBorder.css";

import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/theme/toastui-editor-dark.css";
import { Editor } from "@toast-ui/react-editor";

export default function Home() {
  const jwt = localStorage.getItem("token"); // JWT stored in localStorage
  const [username, setUsername] = useState(localStorage.getItem("username")); // replace with your logic to determine the username

  const [isAuthenticated, setIsAuthenticated] = useState(false); // replace with your logic to determine if the user is authenticated

  const currentDate = new Date();
  const formattedDate = `${currentDate.toLocaleString("default", {
    weekday: "short",
  })}. ${getOrdinalSuffix(currentDate.getDate())}. ${currentDate.toLocaleString(
    "default",
    { month: "long" }
  )} ${currentDate.getFullYear()}`;

  const [todayTilCount, setTodayTilCount] = useState(0);
  const [totalTilCount, setTotalTilCount] = useState(0);

  const editorRef = useRef();

  // Check if the user is authenticated before making the API request
  useEffect(() => {
    if (jwt) {
      const validateToken = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/validateToken`,
            {
              headers: {
                Authorization: `Bearer ${jwt}`,
              },
            }
          );

          if (response.data.success) {
            setIsAuthenticated(true);
            setUsername(response.data.data.username);
          }
        } catch (err) {
          console.error(err);
        }
      };

      validateToken(); // Call the validateToken function
    }
  }, [jwt]);

  // You could fetch today's TIL count, and total TIL count
  const fetchTilCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/blocks/count`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      if (response.data.success) {
        setTodayTilCount(response.data.data.todayCount);
        setTotalTilCount(response.data.data.totalCount);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (jwt) {
      fetchTilCount();
    }
  }, []);

  // Tags
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" && tagInput.trim() && tags.length < 15) {
      setTags([...tags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleTagClick = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    const currEditorInput = editorRef.current.getInstance().getMarkdown();
    // Check if the user is authenticated before making the API request
    if (isAuthenticated) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/blocks`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${jwt}`, // Include the JWT in the Authorization header
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ content: currEditorInput, tags: tags }), // Send the markdownInput as the request body
          }
        );
        editorRef.current.getInstance().setMarkdown("");
        alert("TIL saved!");
      } catch (error) {
        console.error("Failed to save TIL:", error);
      }
    } else {
      console.error("User is not authenticated");
    }
  };

  return isAuthenticated ? (
    <div className="flex-grow h-screen px-40 py-14 bg-black-300 text-white">
      <div className="flex justify-between pb-12">
        <div className="flex flex-col justify-center">
          <p className="text-xl font-bold text-purple-200 pb-6">
            {formattedDate}
          </p>
          <div className="flex flex-col space-y-2">
            <p className="text-3xl font-extrabold">{`Hi, ${username}`}</p>
            <p className="text-3xl font-extrabold">How was your day? 💫</p>
          </div>
        </div>

        <div className="flex gradient-border-1">
          <div className="bg-black-300 text-blue-300 rounded-3xl px-4 py-2 flex inner-content">
            <div className="flex flex-col space-y-5 justify-center text-center px-9 py-6">
              <p className="text-xl font-bold">Today&apos;s TIL</p>
              <p className="text-2xl font-extrabold">{todayTilCount}</p>
            </div>
            <div className="flex flex-col space-y-5 justify-center text-center px-9 py-6">
              <p className="text-xl font-bold">Total TIL</p>
              <p className="text-2xl font-extrabold">{totalTilCount}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <Editor
          ref={editorRef}
          initialValue=""
          previewStyle="tab"
          height="480px"
          initialEditType="markdown"
          theme="dark"
          placeholder="What did you learn today?"
          hideModeSwitch={true}
          useCommandShortcut={true}
        />
        <div className="bg-gray-400 rounded-b-md -mt-1 py-2 flex items-center flex-wrap">
          <span className="mx-4">🏷️</span>
          {tags.map((tag, index) => (
            <button
              className="mr-3 my-1 bg-gray-300 px-2 rounded-md"
              key={index}
              onClick={() => handleTagClick(index)}
            >
              {tag}
            </button>
          ))}
          {tags.length < 10 && (
            <input
              className="my-1 px-2 bg-gray-400 rounded-md h-6 min-w-20 focus:outline-none"
              type="text"
              placeholder="Add tag"
              value={tagInput}
              onChange={handleTagInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleTagInputKeyPress(e);
              }}
              style={tagInput ? { backgroundColor: "#606073" } : {}}
            />
          )}
        </div>

        <div className="flex justify-end">
          <button
            className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-2xl"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex-grow h-screen px-40 py-16 bg-black-300 text-white">
      <div className="flex justify-between pb-12">
        <div className="flex flex-col justify-center">
          <p className="text-xl font-bold text-purple-200 pb-6">
            {formattedDate}
          </p>
          <div className="flex flex-col space-y-2">
            <p className="text-3xl font-extrabold">Hi, Nice to meet you</p>
          </div>
        </div>

        <div className="flex gradient-border-1">
          <div className="bg-black-300 text-blue-300 rounded-3xl px-4 py-2 flex inner-content">
            <div className="flex flex-col space-y-5 justify-center text-center px-9 py-6">
              <p className="text-xl font-bold">Today&apos;s TIL</p>
              <p className="text-2xl font-extrabold">-</p>
            </div>
            <div className="flex flex-col space-y-5 justify-center text-center px-9 py-6">
              <p className="text-xl font-bold">Total TIL</p>
              <p className="text-2xl font-extrabold">-</p>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4">
        Hey What did you learned today?{/* placeholder UI */}
        <div className="flex justify-center">
          <button
            className="mt-6 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-2xl"
            // onClick={handleSave}
          >
            Write my TIL
          </button>
        </div>
      </div>
    </div>
  );
}
