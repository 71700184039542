import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import SignupNicknameModal from "../modals/SignupNicknameModal";

const GoogleLoginButton = ({ onLogin }) => {
  const [googleToken, setGoogleToken] = useState(null);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const loginUser = async (googleToken) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/google`,
        {
          tokenId: googleToken,
        }
      );

      if (response.data.success) {
        // Save the returned token to local storage
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("username", response.data.data.username);
        // console.log("token", response.data.data.token);
        onLogin();
      }
    } catch (err) {
      if (err && err.response && err.response.status === 401) {
        // If no user info exists, show SignupNicknameModal
        setGoogleToken(googleToken); // Set the Google token state
      }
    }
  };

  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          onSuccess={(res) => {
            loginUser(res.credential);
          }}
          onFailure={(err) => {
            console.log(err);
          }}
        />
        {googleToken && (
          <SignupNicknameModal
            googleToken={googleToken}
            onClose={() => setGoogleToken(null)}
            onLogin={onLogin}
          />
        )}
      </GoogleOAuthProvider>
    </>
  );
};

export default GoogleLoginButton;
