import React, { useEffect, useCallback } from "react";
import Viewer from "@toast-ui/editor/dist/toastui-editor-viewer";

let viewer = null; // Create a mutable ref object

const MDViewer = ({ content }) => {
  const viewerRef = useCallback(
    (node) => {
      if (node !== null) {
        viewer = new Viewer({
          el: node,
          initialValue: content,
          previewStyle: "vertical",
          height: "600px",
          usageStatistics: false,
          theme: "dark",
        });
      }
    },
    [content]
  );

  useEffect(() => {
    return () => {
      viewer && viewer.destroy();
    };
  }, []);

  return <div style={{ width: "50vw" }} ref={viewerRef} />;
};

export default MDViewer;
